import React from "react";

import Carousel from "../Carousel";
import EmailForm from "../EmailForm";
import Button from "components/CustomButtons/Button.js";
import Countdown from "components/Countdown";

import styles from "./index.module.scss";

import discordImg from "assets/img/discord.png";
import instagramImg from "assets/img/instagram.svg";

const FrontPage = ({ setOpen }) => {
  return (
    <div className={styles.container}>
      <div className={styles.first}>
        {/* <div className={styles.text}>
          <div>
            Embark on this wild intergalactic journey by minting your own Mad
            Trooper NFT on the Solana blockchain, and joining the grand battle
            that will decide the very fate of the world. Each MadTrooper is
            completely unique and has been algorithmically generated to possess
            special attributes and traits. Additionally, hidden within the army
            are a few mythical warriors that are more powerful, and definitely
            more batshit crazy, than the other MadTroopers.
          </div>
        </div> */}
      </div>

      <div className={styles.carousel}>
        <Carousel />
        {/* <Countdown className={styles["desktop-countdown"]} /> */}
      </div>

      <div className={styles.third}>
        <div className={styles.p2}>
          <p>
            The year is 2177. Humanity has pushed boundaries, establishing
            footholds on Earth, Mars, and recently EDDA, however, unforeseen
            challenges arise. The future of humanity is at stake as it deals
            with a threat that seeks to destroy EDDA and decide the very fate of
            the human race. EDDAVerse’s galactic council has assembled all of
            its members to establish the last stand, and protect the human race.
          </p>
          <p>
            MadTrooper,{" "}
            <a
              href="https://www.arabianbusiness.com/industries/media/eddaverse-plans-worlds-first-web3-movie"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 500 }}
            >
              EDDAVerse’s
            </a>{" "}
            first IP leads the way.
          </p>
          <p>
            Not just a character but a symbol of a passionate Web3 community.
            This elite force consists of 3489 MadTroopers consisting of the most
            feared warriors in the galaxy, wearing outfits that are absolutely
            batshit crazy!
          </p>
        </div>
        <div className={styles.title}>
          <div className={styles["light-text"]}>Are you a</div>
          <div className={styles["bold-text"]}>MadTrooper?</div>
        </div>
        <div className={styles.second}>
          <div className={styles.email}>
            <EmailForm />
          </div>
        </div>
        <div className={styles["social-button"]}>
          <a
            className={styles.socialText}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/themadtrooper/"
          >
            <Button className={styles.instaButton} solid>
              <img
                src={instagramImg}
                style={{ width: "17px", height: "17px", marginRight: "4px" }}
              />
              Instagram
            </Button>
          </a>
          <a
            className={styles.socialText}
            target="_blank"
            rel="noopener noreferrer"
            href="https://discord.gg/eddaverse"
          >
            <Button className={styles.discordButton} solid>
              <img
                src={discordImg}
                style={{ width: "17px", height: "17px", marginRight: "4px" }}
              />
              Discord
            </Button>
          </a>
          <a
            href="https://twitter.com/TheMadTrooper"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialText}
          >
            <Button className={styles.twitterButton} solid>
              <i className={styles.socials + " fab fa-x-twitter"} /> Twitter
            </Button>
          </a>
          <a
            href="https://www.youtube.com/watch?v=rSBGgdMJI6M"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.socialText}
          >
            <Button className={styles.twitterButton} solid>
              <i className={styles.socials + " fab fa-youtube"} /> Youtube
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FrontPage;
