import img1 from "assets/img/AsSeenOn/1.jpeg";
import img2 from "assets/img/AsSeenOn/2.png";
import img4 from "assets/img/AsSeenOn/4.png";
import img5 from "assets/img/AsSeenOn/5.png";
import img6 from "assets/img/AsSeenOn/6.png";
import img7 from "assets/img/AsSeenOn/7.png";
import img8 from "assets/img/AsSeenOn/8.png";
import img9 from "assets/img/AsSeenOn/9.png";
import img10 from "assets/img/AsSeenOn/10.png";
import hyperspace from "./hyperspace.svg";

const teams = [
  {
    id: 1,
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper%2Fasseenon%2Farabianbusiness.jpeg",
    link: "https://www.arabianbusiness.com/industries/media/eddaverse-plans-worlds-first-web3-movie",
    desc: "Arabian Business",
    width: 124.5,
    height: 91.5,
  },
  {
    id: 2,
    image: hyperspace,
    link: "https://hyperspace.xyz/collection/HzHwGWv2VXUYkzY22L23zz1vK8H36jQuShojpAnLRg6U",
    desc: "Hyperspace",
  },
  {
    id: 9,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/crypto-daily.png",
    link: "https://cryptodaily.io/madtrooper/",
    desc: "Crypto Daily",
  },
  {
    id: 1,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/nft-calendar.png",
    link: "https://nftcalendar.io/event/madtrooper-minting-event/",
    desc: "NFT Calendar",
    width: 109.5,
    height: 80,
  },
  {
    id: 4,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/non-fungi.png",
    link: "https://www.non-fungi.com/drops/madtrooper/",
    desc: "Non-fungi",
    width: 186,
    height: 89,
  },
  {
    id: 5,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/blocko.png",
    link: "https://blockonews.com/madtrooper-the-most-awaited-nft-drop-of-2021/",
    desc: "Blockonews",
  },
  {
    id: 6,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/r.png",
    link: "https://howrare.is/drops",
    desc: "HowRare.is",
    width: 90,
  },
  {
    id: 7,
    image:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/asseenon/bullish.png",
    link: "https://www.bullishnet.com/madtrooper-the-most-awaited-nft-drop-of-2021/",
    desc: "Bullishnet",
  },
  {
    id: 10,
    image: img10,
    link: "https://smartliquidity.info/2021/09/20/madtrooper-to-mint-8888-unique-collectible-nfts-on-the-solana-blockchain-on-september-24/",
    desc: "Smart Liquidity",
    height: 80,
  },
];

export default teams;
