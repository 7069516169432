export const BANNERS = [
  {
    url: "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/banner/banner/banner1.png",
    fullUrl:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/banner/banner/banner1_full.png",
    title: "Synergy",
  },
  {
    url: "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/banner/banner/banner2.png",
    fullUrl:
      "https://edda-cdn.fra1.digitaloceanspaces.com/madtrooper/banner/banner/banner2_full.png",
    title: "Exo Force",
  },
];
