// import image1 from "assets/img/themes/cyberpunk.png";
// import image2 from "assets/img/themes/rock.jpeg";

// import image4 from "assets/img/themes/hippie.jpeg";
// import image5 from "assets/img/themes/retro.png";
// import image6 from "assets/img/themes/steampunk.png";



// export const nfts = [
//   { id: 1, image: image1, description: "Theme: Cyberpunk" },
//   { id: 2, image: image2, description: "Theme: Rock" },
  
//   { id: 3, image: image4, description: "Theme: Hippie" },
//   { id: 4, image: image5, description: "Theme: Retro" },
//   { id: 5, image: image6, description: "Theme: Steampunk" },
// ];


import image1 from "assets/img/themes/1.jpeg";
import image2 from "assets/img/themes/2.jpeg";
import image3 from "assets/img/themes/3.jpeg";
import image4 from "assets/img/themes/4.jpeg";
import image5 from "assets/img/themes/5.jpeg";
import image6 from "assets/img/themes/6.jpeg";



export const nfts = [
  { id: 1, image: image1, description: "Theme: Rock" },
  { id: 2, image: image2, description: "Theme: Cyberpunk" },
  { id: 3, image: image3, description: "Theme: Hippie" },
  { id: 4, image: image4, description: "Theme: Cyberpunk" },
  { id: 5, image: image5, description: "Theme: Retro" },
  // { id: 6, image: image6, description: "Theme: Steampunk" },
];

