const introVideo = [
  {
    title: "Surfing through NEOM",
    subTitle: "",
    desc: "MadTrooper and ABDRA3492 surf through The Line, NEOM",
    navText: "Surfing through NEOM",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/mt1.png",
    url: "https://www.youtube.com/watch?v=1cY-vRGus4c",
    showDesc: true,
  },
  {
    title: "MadTrooper Rising",
    subTitle: "",
    desc: "Rise one, rise all!",
    navText: "MadTrooper Rising",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/Screenshot%202023-08-16%20at%202.28.54%20PM.png",
    url: "https://www.youtube.com/watch?v=rSBGgdMJI6M&feature=youtu.be",
    // customStyle: {
    //   backgroundPosition: "35% center",
    // },
    showDesc: true,
  },
  {
    title: "Queen's Table",
    subTitle: "",
    desc: "MadTrooper reports to the Queen's table",
    navText: "Queen's Table",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/Screenshot%202023-08-10%20at%207.17.26%20PM.png",
    url: "https://youtu.be/XqleFc13Y9o",
    // customStyle: {
    //   backgroundPosition: "35% center",
    // },
    subTitleStyle: {
      fontSize: "36px",
      width: "400px",
    },
    titleStyle: {
      fontWeight: "bold",
      width: "400px",
    },
    // contentContainerStyle: {
    //   display: "flex",
    //   flexDirection: "column",
    //   height: "500px",
    // },
    showDesc: true,
  },
  {
    title: "War. to end all war",
    subTitle: "",
    desc: "Taking decisions that others can't to leave a legacy that others won't.",
    navText: "War. to end all war",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/Screenshot%202023-08-16%20at%202.30.22%20PM.png",
    url: "https://youtu.be/ESsaByCAI_0",
    customStyle: {
      backgroundSize: "cover",
    },
    subTitleStyle: {
      fontSize: "36px",
      width: "400px",
    },
    titleStyle: {
      fontWeight: "bold",
      width: "400px",
    },
    showDesc: true,
  },
  {
    title: "MadTrooper Party",
    subTitle: "",
    desc: "MadTrooper breaks into the otherside and parties with BAYC and Crypto Punk!",
    navText: "MadTrooper Party",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/Screenshot%202023-08-10%20at%207.13.27%20PM.png",
    url: "https://youtu.be/235ODyTsF60",
    subTitleStyle: {
      fontSize: "36px",
      width: "400px",
    },
    titleStyle: {
      fontWeight: "bold",
      width: "400px",
    },
    // contentContainerStyle: {
    //   display: "flex",
    //   flexDirection: "column",
    //   height: "600px",
    // },
    showDesc: true,
  },
  {
    title: "Raze",
    subTitle: "",
    desc: "MadTrooper on Planet Tzukanda",
    navText: "Raze",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/mt2.png",
    url: "https://youtu.be/sviNh751Q-c",
    customStyle: {
      backgroundSize: "cover",
    },
    subTitleStyle: {
      fontSize: "36px",
      width: "400px",
    },
    titleStyle: {
      fontWeight: "bold",
      width: "400px",
    },
    // contentContainerStyle: {
    //   display: "flex",
    //   flexDirection: "column",
    //   height: "600px",
    // },
    showDesc: true,
  },
  {
    title: "Energy Cube",
    subTitle: "",
    desc: "MadTrooper retrieves the Energy Cube",
    navText: "Energy Cube",
    image:
      "https://edda-cdn.fra1.cdn.digitaloceanspaces.com/madtrooper/banner/Screenshot%202023-08-10%20at%207.26.19%20PM.png",
    url: "https://youtu.be/4ufBQJ6LphA",
    customStyle: {
      backgroundSize: "cover",
    },
    subTitleStyle: {
      fontSize: "36px",
      width: "400px",
    },
    titleStyle: {
      fontWeight: "bold",
      width: "400px",
    },
    // contentContainerStyle: {
    //   display: "flex",
    //   flexDirection: "column",
    //   height: "600px",
    // },
    showDesc: true,
  },
];

export default introVideo;
